<template>
  <div>
    <a-modal
      :visible="visible"
      :footer="null"
      @cancel="handlePreviewCancel"
      :width="is_video ? '480px' : width"
      :zIndex="10001"
    >
      <div v-if="!previewSrc">没有可预览链接</div>
      <template v-else>
        <div v-if="is_video">
          <video
            id="video"
            :src="video_preview_src"
            controls
            :autoplay="isAutoplay"
            style="height: 480px; width: 100%"
            :controlslist="controlslist"
            @contextmenu.prevent
          >
            你的浏览器不支持video
          </video>
        </div>
        <div v-else style="text-align: center">
          <img class="preview" :src="previewSrc" />
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import _base_https from '@/api/base_api';
import utils from '@/common/util';

export default {
  props: {
    width: {
      type: String,
      default: '480px',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    previewSrc: {
      type: String,
      default: '',
    },
    controlslist: {
      type: String,
      default: 'nodownload',
    },
    isAutoplay: {
      type: Boolean,
      default: false,
    },
    isAuth: {
      type: Boolean,
      default: true,
    },
    previewType: {
      type: String,
      default: '',
    },
  },
  watch: {
    previewSrc: {
      handler: function (res) {
        if (res) {
          // 之前的逻辑
          if (!this.previewType) {
            // console.log(res, utils.fileSuffixTypeUtil(res, this.video_suffix_list))
            if (utils.fileSuffixTypeUtil(res, this.video_suffix_list)) {
              this.is_video = true;
              if (this.isAuth) {
                this.getAuthUrl(res);
              } else {
                this.video_preview_src = res;
              }
            } else {
              this.is_video = false;
            }
          } else {
            if (this.previewType === 'video') {
              if (this.isAuth) {
                this.getAuthUrl(res);
              } else {
                this.video_preview_src = res;
                console.log('this.video_preview_src:', this.video_preview_src);
              }
              this.is_video = true;
            } else {
              this.video_preview_src = '';
              this.is_video = false;
            }
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      is_video: false,
      video_suffix_list: ['mp4', 'mov', 'm4v', 'flv', 'mpeg', '3gp', 'avi'],
      video_preview_src: '',
    };
  },
  methods: {
    handlePreviewCancel() {
      this.video_preview_src = '';
      this.$emit('cancel');
    },
    getAuthUrl(url) {
      _base_https.getAuthUrl({ url: url }).then((res) => {
        if (res.code === 200) {
          this.video_preview_src = res.data;
        } else {
          this.video_preview_src = url;
        }
      });
    },
  },
};
</script>

<style scoped>
.preview {
  max-width: 100%;
}
</style>
