<template>
    <a-card>
        <a-page-header
            title="新建程序化落地页"
        />

        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <div v-show="currentStep == 1">
                <a-form-model-item label="广告主" prop="advertiserId">
                    <a-select
                        v-model="form.advertiserId"
                        placeholder="请选择广告主"
                        show-search
                        option-filter-prop="children"
                        @search="getAccountList"
                        @change="onChangeAdvertiser"
                        v-if="id == 0"
                    >
                        <a-select-option
                            v-for="item in advertiserList"
                            :key="item.id"
                            :name="item.principalName"
                        >
                            {{ item.advertiserName }}{{ item.advertiserCode ? `（${item.advertiserCode}）` : '-' }}
                        </a-select-option>
                    </a-select>
                    <span v-else>{{ form.advertiserName }}</span>
                </a-form-model-item>
                <a-form-model-item label="广告主品牌" :required="true">
                    {{ form.adPrincipalName || '-' }}
                </a-form-model-item>
                <a-form-model-item label="选择账套" prop="adPlatformAccountId">
                    <a-select
                        v-model="form.adPlatformAccountId"
                        placeholder="请选择账套"
                        show-search
                        option-filter-prop="children"
                        :disabled="!form.advertiserId"
                        v-if="id == 0"
                    >
                        <a-select-option
                            v-for="item in platformList"
                            :key="item.adPlatformAccountId"
                        >
                            {{ item.adPlatformAccountName }}
                        </a-select-option>
                    </a-select>
                    <span v-else>{{ form.adPlatformAccountName }}</span>
                </a-form-model-item>
                <a-form-model-item label="程序化落地页适用范围" prop='groupScope'>
                    <a-radio-group v-model="form.groupScope" @change="onChangeScope">
                        <a-radio :value="1">车系</a-radio>
                        <a-radio :value="2">品牌</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                    <a-select
                        v-model="form.principalId"
                        placeholder="请选择品牌"
                        show-search
                        option-filter-prop="children"
                        style="width: 100%;"
                        @change="onChangePrincipal"
                    >
                        <a-select-option
                            v-for="item in principalList"
                            :key="item.id"
                        >
                            {{ item.principal_name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="form.groupScope != 2">
                    <a-select
                        v-model="form.carSeriesId"
                        placeholder="请选择车系"
                        show-search
                        option-filter-prop="children"
                        style="width: 100%;"
                        :disabled="!form.principalId"
                    >
                        <a-select-option
                            v-for="item in seriesList"
                            :key="item.id"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="集客场景" prop='customerScene' style="margin-top: 24px;">
                    <a-radio-group v-model="form.customerScene">
                        <a-radio
                            v-for="item in customerSceneList"
                            :key="item.code"
                            :value="item.code"
                        >
                            {{ item.desc }}
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="地域定向" :required="true" v-if="id == 0">
                    不开启
                </a-form-model-item>
                <a-form-model-item label="流量分配方式" :required="true" v-if="id == 0">
                    智能分配
                </a-form-model-item>
                <a-form-model-item label="落地页组名称" ref="groupTitle" prop="groupTitle">
                    <wordLimitInput 
                        :value="form.groupTitle"
                        :maxLength="20"
                        @onChangeInput="e => onChangeInput(e, 'groupTitle')"
                    ></wordLimitInput>
                    <div class="txt">
                        适用范围为品牌，名称建议填写“品牌+集客场景”
                    </div>
                    <div class="txt">
                        适用范围为车系，名称建议填写“车系+集客场景”
                    </div>
                </a-form-model-item>
            </div>

            <div v-if="currentStep == 2">
                <div class="mt-20 red">落地页组名称：{{ form.groupTitle || '-' }}</div>
                <a-form-model
                    ref="searchForm"
                    :model="searchForm"
                    v-bind='searchLayout'
                    class="mt-20"
                >
                    <a-row>
                        <a-col :span="7">
                            <a-form-model-item label="建站名称">
                                <a-input
                                    v-model="searchForm.adLandingPageName"
                                    placeholder="请输入"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="1"></a-col>
                        <a-col :span="7">
                            <a-form-model-item label="集客场景">
                                <a-select
                                    mode="multiple"
                                    v-model="searchForm.customerSceneList"
                                    placeholder="请选择"
                                    allow-clear
                                    show-search
                                    option-filter-prop="children"
                                >
                                    <a-select-option
                                        v-for="item in customerSceneList"
                                        :key="item.code"
                                    >
                                        {{ item.desc }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="1"></a-col>
                        <a-col :span="8">
                            <a-form-model-item label="车系">
                                <a-select
                                    mode="multiple"
                                    v-model="searchForm.carSeriesIdList"
                                    placeholder="请选择车系"
                                    allow-clear
                                    show-search
                                    option-filter-prop="children"
                                >
                                    <a-select-option
                                        v-for="item in searchSeriesList"
                                        :key="item.id"
                                    >
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="flex-end">
                            <a-space>
                                <a-button @click="handleReset">重置</a-button>
                                <a-button type="primary" @click="handleSearch">查询</a-button>
                            </a-space>
                        </a-col>
                    </a-row>
                </a-form-model>

                <div class="mt-20">已选中{{ selectedRowKeys.length }}个落地页</div>
                <a-table
                    :loading="loading"
                    :columns="columns"
                    :data-source="dataList"
                    :rowKey="record => record.hjkAdLandingPageId"
                    :row-selection="rowSelection"
                    :pagination='false'
                    class="mt-20"
                >
                    <div
                        slot="imgUrl"
                        slot-scope="text, record"
                    >
                        <span v-if="record.thumbnail">
                            <img :src="record.thumbnail" class="img" v-if="record.adLandingPageStatus == 'DELETED'">
                            <img :src="record.thumbnail" class="img" v-else @click="handlePreviewOpen(record.thumbnail, 'img')">
                        </span>
                    </div>
                    <div
                        slot="operation"
                        slot-scope="text, record"
                    >
                        <a @click="updateState(record)">{{ record.state == 1 ? '启用' : '禁用' }}</a>
                    </div>
                </a-table>

                <base-pagination
                    :currentPage="pagination.current"
                    :pageSize="pagination.pageSize"
                    :total="pagination.total"
                    @onChange="handlePaginationChange"
                    @onShowSizeChange="handlePaginationChange"
                />
            </div>

            <a-form-model-item label="" :colon="false" class="mt-20">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'下一步'"
                        @onClickBtn="nextPage"
                        v-if="currentStep == 1 && id == 0"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'上一步'"
                        @onClickBtn="prevPage"
                        v-if="currentStep == 2 && id == 0"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="validateForm"
                        v-if="currentStep == 2 || id != 0"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>

        <preview-modal
            :visible="previewVisible"
            :preview-src="previewSrc"
            width="50%"
            @cancel="handlePreviewCancel"
        />
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'
import debounce from 'lodash/debounce'
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";

const statusList = [
    { id: "ENABLE", label: "已发布, 已生效" },
    { id: "DISABLE", label: "不可用" },
    { id: "DELETED", label: "已删除" },
    { id: "EDIT", label: "未发布, 未生效" },
    { id: "FORBIDDEN", label: "惩罚广告主, 站点下线" },
    { id: "AUDIT_ACCEPTED", label: "审核通过" },
    { id: "AUDIT_REJECTED", label: "审核拒绝" },
    { id: "AUDIT_BANNED", label: "审核封禁" },
    { id: "AUDIT_DOING", label: "审核中" },
]

export default {
    components: {
        wordLimitInput,
        previewModal
    },
    data() {
        this.getAccountList = debounce(this.getAccountList, 1000)

        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }
        
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                advertiserId: undefined,
                adPlatformAccountId: undefined,
                adPrincipalName: '',
                groupScope: 1,
                principalId: undefined,
                carSeriesId: undefined,
                customerScene: 1,
                groupTitle: '',
            },
            rules: {
                advertiserId: [
                    { required: true, message: '请选择广告主', trigger: 'change' }
                ],
                adPlatformAccountId: [
                    { required: true, message: '请选择账套', trigger: 'change' }
                ],
                groupScope: [
                    { required: true, message: '请选择组件适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
                customerScene: [
                    { required: true, message: '请选择集客场景', trigger: 'change' }
                ],
                groupTitle: [
                    { required: true, message: '请填写落地页组名称', trigger: 'change' }
                ],
            },
            isSubmit: false,
            principalList: [],
            seriesList: [],
            customerSceneList: [],
            currentStep: 1,
            advertiserList: [],
            platformList: [],
            searchLayout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            searchForm: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '站点创建时间',
                    dataIndex: 'ctime',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '橙子建站状态',
                    customRender:(text, row) => {
                        let label = ''
                        try {
                            label = (statusList.filter(item => item.id == row.adLandingPageStatus))[0].label
                        } catch(res) {
                            label = '-'
                        }
                        return label
                    }
                },
                {
                    align: 'center',
                    title: '站点缩略图',
                    scopedSlots: { customRender: 'imgUrl' },
                },
                {
                    align: 'center',
                    title: '建站名称',
                    dataIndex: 'videoTitle',
                    customRender:(text, row) => {
                        let txt = <div><a on-click={() => this.handlePreviewOpen(row.previewUrl, 'url')}>{ row.adLandingPageName }</a></div>
                        return row.adLandingPageStatus == 'DELETED' ? row.adLandingPageName : txt
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text ||'-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            selectedRowKeys: [],
            searchSeriesList: [],
            previewVisible: false,
            previewSrc: '',
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.adLandingPageStatus == 2,
                        // state: record.state
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getCustomerScene()
        this.getPrincipalSeriesList('', 'search')

        if(this.id == 0) {
            this.getAccountList()
        } else {
            this.getDetail()
        }
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.form.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.form.principalId)
            }
        },
        onChangePrincipal(id) {
            this.form.carSeriesId = undefined
            this.seriesList = []
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id, type) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    if(type == 'search') {
                        this.searchSeriesList = res.data
                    } else {
                        this.seriesList = res.data
                    }
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        //获取广告主列表
        getAccountList(advertiserName) {
            this.$api.core.materialManage.getLikeAdvertiserNameList({ advertiserName: advertiserName, page: 1, size: 1000 }).then((res) => {
                if(res.code == 200) {
                    this.advertiserList = res.data.list
                } else {
                    this.$message.error(`获取广告主列表失败，${res.message}`)
                }
            })
        },
        onChangeAdvertiser(val, option) {
            this.form.adPrincipalName = option.data.attrs.name
            this.form.adPlatformAccountId = undefined
            this.getPlatformAccountList()
        },
        //获取广告平台帐套列表
        getPlatformAccountList() {
            this.$api.core.materialManage.getPlatformAccountList({advertiserId: this.form.advertiserId }).then((res) => {
                if(res.code == 200) {
                    this.platformList = res.data
                } else {
                    this.$message.error(`获取平台帐套列表失败，${res.message}`)
                }
            })
        },
        onChangeInput(val, type) {
            this.form[type] = val
            this.$refs[type].onFieldChange()
        },
        getDetail() {
            this.$api.core.materialManage.getAdLandingPageGroupDetail(this.id).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    if(_data.groupScope == 1) {
                        this.getPrincipalSeriesList(_data.principalId)
                    }
                    Object.assign(this.form, _data)
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/programmaticLandingPage'
                    })
                }
            })
        },
        nextPage() {
            this.validateForm('next')
            this.getDataList()
        },
        prevPage() {
            this.currentStep = 1
            this.selectedRowKeys = []
        },
        validateForm(type) {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    if(this.form.groupScope == 2) {
                        this.form.carSeriesId = undefined
                    }
                    if(type == 'next') {
                        this.currentStep = 2
                    } else {
                        if(this.id == 0 && this.selectedRowKeys.length < 2) {
                            this.$message.error('请选择2个或以上落地页')
                        } else {
                            this.handleSubmit()
                        }
                    }
                } else {
                    this.$message.error('请填写完整后再次点击')
                }
            })
        },
        handleSubmit() {
            this.isSubmit = true
            if(this.form.groupScope == 2) {
                this.form.carSeriesId = undefined
            }
            let body = {
                data: {
                    ...this.form,
                    hjkAdLandingPageId: this.selectedRowKeys
                },
                method: this.id == 0 ? 'post' : 'put'
            }
            this.$api.core.materialManage.submitAdLandingPageGroupDetail( body ).then(res => {
                this.isSubmit = false
                if(res.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.push({
                        path: '/programmaticLandingPage'
                    })
                } else {
                    this.$message.error(`操作失败，${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.searchForm = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        //获取落地页
        getDataList() {
            this.loading = true
            let params = {
                ...this.searchForm,
                advertiserId: this.form.advertiserId,
                adPlatformAccountId: this.form.adPlatformAccountId,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getPageByAdPlatformAccountIdList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        handlePreviewOpen(url, type) {
            if(type == 'url') {
                window.open(url)
            } else {
                this.previewVisible = true
                this.previewSrc = url
            }
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.txt {
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.5;
}
.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.img {
    width: 60px;
}
</style>
