<template>
    <div class="input">
        <a-input v-model="inputValue" :placeholder="placeholder" allow-clear :minLength="minLength" :maxLength="maxLength" @change="onChangeInput" />
        <span class="num">{{ length }}/{{ maxLength }}</span>
    </div>
</template>

<script>
export default {
    name: 'wordLimitInput',
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        minLength: {
            type: Number,
            default: 0
        },
        maxLength: {
            type: Number,
            default: 0
        }
    },
    watch: {
        value: {
            handler: function (val) {
                this.inputValue = val.slice(0, this.maxLength)
                this.length = this.inputValue.length
            },
            immediate: true,
            deep: true,
        }
    },
    data() {
        return {
            inputValue: '',
            length: 0
        }
    },
    methods: {
        onChangeInput() {
            this.length = this.inputValue.length
            this.$emit('onChangeInput', this.inputValue)
        },

    }
}
</script>

<style scoped lang="less">
.input {
    position: relative;
    /deep/ .ant-input {
        width: 100%;
    }
    .num {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(10px, -50%);
    }
}
.icon {
    cursor: pointer;
}

</style>